import { contentRegex } from "../../utils/validators";
import {
  Box,
  Button,
  CircularProgress,
  Grid as MuiGrid,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Autocomplete,
  FormContainer,
  Input,
  Grid,
  Table,
} from "../../components";
import _ from "lodash";
import {
  commissionAllDoctorsColumns,
  commissionColumns,
  commissionSumColumns,
} from "../../utils/columns";

export const commissionDocumentTypes = [
  { label: "Recepty" },
  { label: "Zamówienia" },
];

export const initialFormData = {
  type: commissionDocumentTypes[0],
  from: "",
  to: "",
};

export const initialAdminFormData = {
  ...initialFormData,
  doctor: {
    pk: undefined,
    label: `Wszyscy terapeuci`,
  },
};

export const initialErrorData = {
  doctor: { value: false, required: true },
  type: { value: false, required: true },
  from: { value: false, validate: contentRegex, required: true },
  to: { value: false, validate: contentRegex, required: true },
  form: { value: false, message: "" },
};

export const ReceiptItem = ({ title, content }) => (
  <TableRow hover>
    <TableCell size="small" align="right" width="40%">
      <Typography fontWeight={500} fontSize={12}>
        {`${title}:`}
      </Typography>
    </TableCell>
    <TableCell size="small">
      <Typography fontSize={12}>{content}</Typography>
    </TableCell>
  </TableRow>
);

export const SearchBar = ({
  isAdmin,
  formData,
  doctors,
  changeFormData,
  isWaitingForResponse,
  errorData,
  onSubmit,
}) => {
  return (
    <FormContainer title="Opcje wyszukiwania">
      {isAdmin && (
        <Autocomplete
          id="autocomplete-doctor"
          label="Terapeuta"
          value={formData["doctor"]}
          options={_.map(doctors, (doctor, index) => ({
            ...doctor,
            label:
              doctor?.firstName && doctor?.lastName
                ? `${doctor.firstName} ${doctor.lastName} (ID: ${doctor.pk})`
                : `ID: ${doctor.pk} - brak danych`,
          }))}
          onChange={changeFormData}
          disabled={isWaitingForResponse}
          error={errorData["doctor"].value}
          errorMessage="Wybierz terapeutę."
          gridProps={{ xs: 12, sm: 6, md: 3 }}
        />
      )}
      <Autocomplete
        id="autocomplete-type"
        label="Rodzaj sprawozdania"
        value={formData["type"]}
        options={commissionDocumentTypes}
        onChange={changeFormData}
        disabled={isWaitingForResponse}
        error={errorData["type"].value}
        errorMessage="Wybierz rodzaj sprawozdania."
        gridProps={{ xs: 12, sm: 6, md: 2 }}
      />
      <Input
        id="from"
        label="Data od"
        type="date"
        value={formData["from"]}
        onChange={changeFormData}
        disabled={isWaitingForResponse}
        error={errorData["from"].value}
        errorMessage={"Podaj datę OD."}
        gridProps={{ xs: 12, sm: 6, md: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Input
        id="to"
        label="Data do"
        type="date"
        value={formData["to"]}
        onChange={changeFormData}
        disabled={isWaitingForResponse}
        error={errorData["to"].value}
        errorMessage={"Podaj datę DO."}
        gridProps={{ xs: 12, sm: 6, md: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <MuiGrid item xs={12} md={2}>
        <Box sx={{ position: "relative", width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            disabled={isWaitingForResponse}
            onClick={onSubmit}
          >
            Pokaż
          </Button>
          {isWaitingForResponse && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </MuiGrid>
    </FormContainer>
  );
};

export const CommissionTable = ({ data, url }) => (
  <>
    <FormContainer title="Podsumowanie" />
    <MuiTable sx={{ marginBottom: 2 }}>
      <TableBody>
        {_.map(commissionSumColumns, (item, index) =>
          !!data?.total?.[item.obj] || _.isNumber(data?.total?.[item.obj]) ? (
            <ReceiptItem
              key={`sumItem-${index}`}
              title={item.title}
              content={
                !!item.valueGetter
                  ? item.valueGetter(data?.total?.[item.obj])
                  : data?.total?.[item.obj]
              }
            />
          ) : null
        )}
      </TableBody>
    </MuiTable>
    {data.data ? (
      <Table
        columns={commissionAllDoctorsColumns}
        data={data.data}
        title={data.title}
        checking={false}
        initialOrderBy="fullName"
      />
    ) : (
      <Grid
        columns={commissionColumns}
        url={url}
        title={data?.title}
        checking={false}
      />
    )}
  </>
);
