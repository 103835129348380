import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemButton,
  ListItemText,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Bookmarks,
  Clear,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
} from "@mui/icons-material";
import { getClientLabel, getIngredientLabel } from "../../utils/helpers/app";
import {
  Autocomplete,
  FormContainer,
  Input,
  PageTitle,
  Popover,
} from "../../components";
import _ from "lodash";
import {
  commissionTypes,
  doseFreqTypes,
  receiptTypes,
  dialogTypes,
} from "../../utils/enums";
import { hasIngredientError } from "./utils";

export const ReceiptItem = ({ title, content }) => (
  <TableRow hover>
    <TableCell size="small" align="right" width="40%">
      <Typography fontWeight={500} fontSize={12}>
        {`${title}:`}
      </Typography>
    </TableCell>
    <TableCell size="small">
      <Typography fontSize={12}>{content}</Typography>
    </TableCell>
  </TableRow>
);

export const IngredientAutocomplete = ({ data, categories, ...rest }) => {
  const [expandedGroups, setExpandedGroups] = useState([]);

  useEffect(() => {
    setExpandedGroups(_.map(categories, (category) => category.name));
  }, [categories]);

  const manageExpandedGroups = (name) => () => {
    const currentExpandedGroups = _.cloneDeep(expandedGroups);
    const isExpanded = _.some(currentExpandedGroups, (group) => group === name);

    if (isExpanded) {
      setExpandedGroups(
        _.filter(currentExpandedGroups, (item) => name !== item)
      );
    } else {
      setExpandedGroups([...currentExpandedGroups, name]);
    }
  };

  const onInputChange = (e, value, reason) => {
    if (reason === "input") {
      if (!!value)
        setExpandedGroups(_.map(categories, (category) => category.name));
      else setExpandedGroups([]);
    }
    if (reason === "reset") {
      setExpandedGroups([]);
    }
  };

  return (
    <Autocomplete
      {...rest}
      label="Nazwa składnika"
      options={_.orderBy(data, ["category"], ["desc"])}
      optionLabel={getIngredientLabel}
      errorMessage="Wybierz składnik."
      groupBy={(option) => option.category}
      onInputChange={onInputChange}
      renderGroup={(params) => {
        const isExpanded = _.some(
          expandedGroups,
          (group) => group === params.group
        );
        return (
          <React.Fragment key={`${params.group}-${params.key}`}>
            <ListItemButton
              key={params.key}
              onClick={manageExpandedGroups(params.group)}
              divider
              selected
            >
              <ListItemText primary={params.group} />
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isExpanded} unmountOnExit>
              {params.children}
            </Collapse>
          </React.Fragment>
        );
      }}
      gridProps={{ xs: 8, md: 4 }}
    />
  );
};

export const ReceiptForm = ({
  isEditMode,
  isCalculated,
  isWaiting,
  formData,
  errorData,
  clients,
  ingredientsData,
  ingredientCategories,
  setDialog,
  handleChangeForm,
  handleAddIngredient,
  handleDeleteIngredient,
}) => {
  const [activeIndex, setActiveIndex] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const isWywar = formData.receiptType.value === receiptTypes[0].value;

  const handleOpenIngredient = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveIndex(index);
  };

  const handleCloseIngredient = () => {
    setAnchorEl(null);
    setActiveIndex();
  };

  const hasDetails = (item) =>
    !!item &&
    (!!item?.contraindications ||
      !!item?.cycle ||
      !!item?.directonOfAction ||
      !!item?.generalCharacteristic ||
      !!item?.placeOfAction ||
      !!item?.substitute ||
      !!item?.tasteAndThermals ||
      !!item?.category);

  const PopoverBodyRow = ({ title, description, direction = "row" }) => (
    <Box
      display="flex"
      flexDirection={direction}
      gap={direction === "row" ? "1rem" : 0}
    >
      <Typography variant="overline">{`${title}:`}</Typography>
      <Typography variant="button" lineHeight={direction === "row" ? 2.2 : 1.5}>
        {description}
      </Typography>
    </Box>
  );

  const PopoverBody = ({ item }) => (
    <>
      {!!item?.category && (
        <PopoverBodyRow title="Rodzina" description={item.category} />
      )}
      {!!item?.tasteAndThermals && (
        <PopoverBodyRow
          title="Smak i termika"
          description={item.tasteAndThermals}
        />
      )}
      {!!item?.cycle && (
        <PopoverBodyRow title="Obieg" description={item.cycle} />
      )}
      {!!item?.directonOfAction && (
        <PopoverBodyRow
          title="Kierunek działania"
          description={item.directonOfAction}
        />
      )}
      {!!item?.placeOfAction && (
        <PopoverBodyRow
          title="Miejsce działania"
          description={item.placeOfAction}
        />
      )}
      {!!item?.doseText && (
        <PopoverBodyRow title="Dawka" description={item.doseText} />
      )}
      {!!item?.substitute && (
        <PopoverBodyRow title="Zamiennik" description={item.substitute} />
      )}
      {!!item?.generalCharacteristic && (
        <PopoverBodyRow
          title="Ogólna charakterystyka"
          description={
            <div
              dangerouslySetInnerHTML={{
                __html: item.generalCharacteristic,
              }}
            />
          }
          direction="column"
        />
      )}
      {!!item?.contraindications && (
        <PopoverBodyRow
          title="Przeciwwskazania"
          description={
            <div
              dangerouslySetInnerHTML={{
                __html: item.contraindications,
              }}
            />
          }
          direction="column"
        />
      )}
    </>
  );

  return (
    <Paper elevation={isEditMode ? 0 : 1}>
      {!isEditMode && (
        <Box
          display="flex"
          alignItems="center"
          paddingRight="16px"
          gap="1rem"
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            padding: { xs: "1rem 0", sm: "0" },
          }}
        >
          <PageTitle>Nowa recepta</PageTitle>
          <Button
            startIcon={<Clear />}
            size="small"
            onClick={() =>
              setDialog(
                dialogTypes.accept,
                "Czy chcesz wyczyścić aktualny formularz?"
              )
            }
            disabled={isCalculated}
          >
            Wyczyść formularz
          </Button>
          <Button
            startIcon={<Bookmarks />}
            variant="outlined"
            size="small"
            onClick={() => setDialog(dialogTypes.edit)}
            disabled={isCalculated}
          >
            Przykładowe recepty
          </Button>
        </Box>
      )}

      <FormContainer title="Dane klienta">
        <Autocomplete
          id="autocomplete-client"
          label="Klient"
          value={formData["client"]}
          options={_.sortBy(clients, ["fullName"])}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          optionLabel={getClientLabel}
          error={errorData["client"].value}
          errorMessage="Wybierz klienta."
          gridProps={{ xs: 12, sm: 6 }}
        />
        <Input
          id="address"
          label="Adres dostawy"
          value={formData["address"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          error={errorData["address"].value}
          errorMessage="Uzupełnij adres."
          helperText={
            !errorData["address"].value &&
            "Wpisz adres w kolejności ulica | kod pocztowy | miasto - rozdzielając je przecinkami."
          }
          gridProps={{ xs: 12, sm: 6 }}
        />
      </FormContainer>

      <FormContainer title="Dane recepty">
        <Autocomplete
          id="autocomplete-receiptType"
          label="Rodzaj recepty"
          value={formData["receiptType"]}
          options={receiptTypes}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          error={errorData["receiptType"].value}
          errorMessage="Wybierz rodzaj recepty."
          gridProps={{ xs: 6, sm: 3 }}
        />
        <Input
          id="dailyDose"
          label="Dzienna dawka [g]"
          type="number"
          inputProps={{ min: "0" }}
          value={formData["dailyDose"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          error={errorData["dailyDose"].value}
          errorMessage="Wpisz dzienną dawkę."
          gridProps={{ xs: 6, sm: 3 }}
        />
        <Input
          id="dosePeriod"
          label="Liczba dni"
          type="number"
          inputProps={{ min: "0" }}
          value={formData["dosePeriod"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          error={errorData["dosePeriod"].value}
          errorMessage="Wpisz ilość dni."
          gridProps={{ xs: 6, sm: 3 }}
        />
        <Autocomplete
          id="autocomplete-doseFreq"
          label="Dawkowanie"
          value={formData["doseFreq"]}
          options={doseFreqTypes}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          error={errorData["doseFreq"].value}
          errorMessage="Wybierz dawkowanie."
          gridProps={{ xs: 6, sm: 3 }}
        />
      </FormContainer>

      <FormContainer title="Składniki recepty" alignItems="center">
        {_.map(formData.ingredients, (ingredient, index) => (
          <React.Fragment key={ingredient.id}>
            <Popover
              open={index === activeIndex}
              anchorEl={anchorEl}
              onClose={handleCloseIngredient}
            >
              <PopoverBody item={ingredient?.value} />
            </Popover>
            <IngredientAutocomplete
              id={`autocomplete-ingredients-${ingredient.id}`}
              value={formData["ingredients"][ingredient.id].value}
              data={ingredientsData}
              categories={ingredientCategories}
              onChange={handleChangeForm}
              disabled={isWaiting || isCalculated}
              error={hasIngredientError("value", ingredient.id, errorData)}
              prefixIcon={
                <IconButton
                  onClick={(e) => handleOpenIngredient(e, index)}
                  size="small"
                  disabled={!hasDetails(ingredient.value)}
                >
                  <InfoOutlined />
                </IconButton>
              }
            />
            <Input
              id={`autocomplete-ingredients-${ingredient.id}-count`}
              label="Ilość [g]"
              type="number"
              inputProps={{ min: "0" }}
              value={formData["ingredients"][ingredient.id].count}
              onChange={handleChangeForm}
              disabled={isWaiting || isCalculated}
              error={hasIngredientError("count", ingredient.id, errorData)}
              errorMessage="Wpisz ilość."
              gridProps={{ xs: 4, md: 2 }}
            />
            <Grid
              item
              xs={3}
              md={2}
              id={`autocomplete-ingredients-${ingredient.id}-dose`}
            >
              <div>Dzienna dawka</div>
              <div style={{ fontWeight: "bold" }}>
                {`${
                  formData["ingredients"]?.[ingredient?.id]?.dose
                    ? formData["ingredients"][ingredient.id].dose.toFixed(2)
                    : "0"
                } g`}
              </div>
            </Grid>
            <Grid
              id={`autocomplete-ingredients-${ingredient.id}-totalPrice`}
              item
              xs={3}
              md={2}
            >
              <div>Kwota</div>
              <div style={{ fontWeight: "bold" }}>
                {`${
                  formData["ingredients"]?.[ingredient?.id]?.totalPrice
                    ? +formData["ingredients"][
                        ingredient.id
                      ].totalPrice.toFixed(2)
                    : "0"
                } zł`}
              </div>
            </Grid>
            <Grid item xs={3} md={1}>
              <Button
                variant="outlined"
                fullWidth
                disabled={
                  isWaiting ||
                  isCalculated ||
                  formData?.ingredients?.length === 1
                }
                onClick={() =>
                  handleDeleteIngredient(
                    formData["ingredients"][ingredient.id].id
                  )
                }
              >
                Usuń
              </Button>
            </Grid>
            {formData.ingredients.length === index + 1 && (
              <Grid item xs={3} md={1}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isWaiting || isCalculated}
                  onClick={handleAddIngredient}
                >
                  Nowy
                </Button>
              </Grid>
            )}
            {index + 1 !== formData?.products?.length && (
              <Divider flexItem sx={{ width: "100%", paddingTop: "16px" }} />
            )}
          </React.Fragment>
        ))}
        {isWywar && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="autocomplete-doublePrepare"
                  color="primary"
                  checked={formData["doublePrepare"]}
                  onChange={handleChangeForm}
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={isWaiting || isCalculated}
                />
              }
              label="Czy składniki mają być podwójnie ugotowane? (+30 zł)"
              sx={{
                padding: "5px",
              }}
            />
          </Grid>
        )}
      </FormContainer>

      <FormContainer title="Szczegóły recepty">
        <Input
          id="name"
          label="Nazwa recepty"
          value={formData["name"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          error={errorData["name"].value}
          errorMessage="Wpisz nazwę recepty."
          gridProps={{ xs: 12, sm: 4 }}
        />
        <Input
          id="description"
          label="Nazwa recepty (notatka)"
          value={formData["description"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          error={errorData["description"].value}
          errorMessage="Wpisz prawidłowo notatkę do recepty."
          gridProps={{ xs: 12, sm: 8 }}
        />
        <Input
          id="notesForClient"
          label="Notatka dla klienta"
          value={formData["notesForClient"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          multiline
          rows={3}
          error={errorData["notesForClient"].value}
          errorMessage="Wpisz prawidłowo notatkę dla klienta."
          gridProps={{ xs: 12 }}
        />
        <Input
          id="notesForEmployee"
          label="Notatka dla pracownika"
          value={formData["notesForEmployee"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          multiline
          rows={3}
          helperText="Uwagi, aby wyprodukować receptę. Informacje te będą dostępne dla pracowników. Informacje dotyczące adresów będą ignorowane w powyższej nocie."
          error={errorData["notesForEmployee"].value}
          errorMessage="Wpisz prawidłowo komentarz dla pracowników."
          gridProps={{ xs: 12 }}
        />
      </FormContainer>
    </Paper>
  );
};

export const ReceiptPricesForm = ({
  isCalculated,
  isEditMode,
  isWaiting,
  formData,
  priceData,
  errorPriceData,
  handleChangePriceForm,
}) =>
  isCalculated && (
    <Paper elevation={isEditMode ? 0 : 1}>
      <PageTitle>Podsumowanie</PageTitle>

      <FormContainer title="Informacje" />
      <MuiTable sx={{ marginBottom: 2 }}>
        <TableBody>
          <ReceiptItem
            title="Liczba dawek"
            content={`${formData["doseFreq"].label} ${
              +formData["dailyDose"] / formData["doseFreq"].value
            } [g]`}
          />
          <ReceiptItem
            title="Czas użytkowania"
            content={`${formData["dosePeriod"]} dzień(dni)`}
          />
          <ReceiptItem
            title="Dzienna dawka"
            content={`${+formData["dailyDose"]} [g]`}
          />
          <ReceiptItem
            title="Waga całkowita"
            content={`${formData["dailyDose"] * formData["dosePeriod"]} [g]`}
          />
        </TableBody>
      </MuiTable>

      <FormContainer title="Ceny">
        <Autocomplete
          id={`autocomplete-commissionType`}
          label="Typ prowizji"
          value={priceData["commissionType"]}
          options={commissionTypes}
          onChange={handleChangePriceForm}
          disabled={isWaiting || !isCalculated}
          error={errorPriceData["commissionType"].value}
          errorMessage="Wybierz typ prowizji."
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="commission"
          label="Wartość prowizji"
          type="number"
          inputProps={{ min: "0" }}
          value={priceData["commission"]}
          onChange={handleChangePriceForm}
          disabled={isWaiting || !isCalculated}
          error={errorPriceData["commission"].value}
          errorMessage="Wpisz prowizję."
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="commissionPrice"
          label="Kwota prowizji bez VAT"
          type="number"
          inputProps={{ min: "0" }}
          value={priceData["commissionPrice"]}
          disabled
          gridProps={{ xs: 6, sm: 4 }}
        />
        {/* <Input
          id="deliveryCost"
          label="Koszt dostawy z VAT"
          type="number"
          inputProps={{ min: "0" }}
          value={priceData["deliveryCost"]}
          disabled
          gridProps={{ xs: 6, sm: 3 }}
        /> */}
        <Input
          id="ppp"
          label="Kwota składników bez prowizji/VAT"
          type="number"
          inputProps={{ min: "0" }}
          disabled
          value={priceData["ppp"]}
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="spp"
          label="Kwota sprzedaży z prowizją/VAT"
          type="number"
          inputProps={{ min: "0" }}
          disabled
          value={priceData["spp"]}
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="sppp"
          label="Całkowity koszt"
          type="number"
          inputProps={{ min: "0" }}
          disabled
          value={priceData["sppp"]}
          gridProps={{ xs: 12, sm: 4 }}
        />
      </FormContainer>
    </Paper>
  );
